import { useMemo, useRef } from "react";
import TagManager from "react-gtm-module";
import { useRouter } from "next/router";
import { Interpolation, Theme, css } from "@emotion/react";
import { Button } from "components/buttons/Button";
import { ContentCard } from "components/cards/ContentCard";
import { SectionScaffold } from "components/sections/SectionScaffold";
import { useMixContentQuery } from "graphql/generated";
import { slidein } from "styles/animation/slidein";
import { LIMIT } from "utils/buildQueryVariables";

export const OriginalSection = ({
  contentSection,
  variables,
  limit = LIMIT.original,
  ...props
}: {
  contentSection: ContentSection;
  variables: MixContentQueryVariables;
  limit?: number;
} & { css?: Interpolation<Theme> }) => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);

  const { data } = useMixContentQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      limit,
      offset: 0,
    },
  });

  const contents = useMemo(() => (data ? data.mix_content : []), [data]);

  return (
    <SectionScaffold {...props}>
      <SectionScaffold.Header>
        <SectionScaffold.Title>오리지널 콘텐츠</SectionScaffold.Title>
        <SectionScaffold.Controls>
          <Button
            size="small"
            color="grey"
            variant="text"
            endIcon="ChevronRight"
            onClick={() => router.push("/original")}
          >
            더보기
          </Button>
        </SectionScaffold.Controls>
      </SectionScaffold.Header>
      <SectionScaffold.Contents>
        <div
          ref={ref}
          css={css`
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 24px;
            overflow: auto;
            scroll-snap-type: x mandatory;
            scroll-padding: var(--page-padding);
            animation: 600ms ${slidein};

            ::-webkit-scrollbar {
              display: none;
            }

            @media (max-width: 1024px) {
              display: flex;
              gap: 24px;
              max-width: 100vw;
              padding: 0 var(--page-padding);
              margin: 0 calc(-1 * var(--page-padding));
            }

            @media (max-width: 768px) {
              gap: 12px;
            }
          `}
        >
          {contents.map((content) => (
            <ContentCard
              css={css`
                scroll-snap-align: start;
                width: 320px;
              `}
              key={content.id}
              type={content.released ? "original" : "upcoming"}
              device="web+tablet"
              content={content}
              onClick={() => {
                const params = {
                  content_section: contentSection,
                  content_url: content.originalurl,
                  content_isoriginal: true,
                  content_released: content.released,
                };

                TagManager.dataLayer({
                  dataLayer: {
                    event: "content_click",
                    ...params,
                    content_id: content.id,
                    content_title: content.title,
                  },
                });

                window.fbq("track", "ViewContent", {
                  ...params,
                  content_ids: [content.id],
                  content_name: content.title,
                  content_type: "product",
                });
              }}
            />
          ))}
        </div>
      </SectionScaffold.Contents>
    </SectionScaffold>
  );
};
