import { ClassAttributes, HTMLAttributes } from "react";
import { Tilerail } from "./Tilerail";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Text } from "components/Text";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 180px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 12px;
  user-select: none;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 88px;
  }
`;

const TailContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 8px;

    @media (max-width: 1024px) {
      margin-right: 8px;
    }
  }
`;

export const MainBanner = ({
  ...props
}: ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
  }) => {
  const theme = useTheme();

  return (
    <Container {...props}>
      <TailContainer
        css={css`
          right: 18%;

          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <Tilerail
          direction={1}
          items={[
            "/img/tile_1.png",
            "/img/tile_2.png",
            "/img/tile_3.png",
            "/img/tile_4.png",
            "/img/tile_5.png",
          ]}
          edge={80}
          duration={15}
          height={160}
          gap={8}
        />
        <Tilerail
          direction={-1}
          items={[
            "/img/tile_6.png",
            "/img/tile_7.png",
            "/img/tile_8.png",
            "/img/tile_9.png",
            "/img/tile_10.png",
          ]}
          edge={80}
          duration={15}
          height={160}
          gap={8}
        />
        <Tilerail
          direction={1}
          items={[
            "/img/tile_11.png",
            "/img/tile_12.png",
            "/img/tile_13.png",
            "/img/tile_14.png",
            "/img/tile_15.png",
          ]}
          edge={80}
          duration={15}
          height={160}
          gap={8}
        />
      </TailContainer>
      <TailContainer
        css={css`
          display: none;
          right: 10%;

          @media (max-width: 1024px) {
            display: flex;
          }

          @media (max-width: 768px) {
            display: none;
          }
        `}
      >
        <Tilerail
          direction={1}
          items={[
            "/img/tile_1.png",
            "/img/tile_2.png",
            "/img/tile_3.png",
            "/img/tile_4.png",
            "/img/tile_5.png",
          ]}
          edge={64}
          duration={15}
          height={173}
          gap={8}
        />
        <Tilerail
          direction={-1}
          items={[
            "/img/tile_6.png",
            "/img/tile_7.png",
            "/img/tile_8.png",
            "/img/tile_9.png",
            "/img/tile_10.png",
          ]}
          edge={64}
          duration={15}
          height={173}
          gap={8}
        />
        <Tilerail
          direction={1}
          items={[
            "/img/tile_11.png",
            "/img/tile_12.png",
            "/img/tile_13.png",
            "/img/tile_14.png",
            "/img/tile_15.png",
          ]}
          edge={64}
          duration={15}
          height={173}
          gap={8}
        />
      </TailContainer>
      <TailContainer
        css={css`
          display: none;
          right: 24px;

          @media (max-width: 768px) {
            display: flex;
          }
        `}
      >
        <Tilerail
          direction={1}
          items={[
            "/img/tile_1.png",
            "/img/tile_2.png",
            "/img/tile_3.png",
            "/img/tile_4.png",
            "/img/tile_5.png",
            "/img/tile_6.png",
            "/img/tile_7.png",
            "/img/tile_8.png",
            "/img/tile_9.png",
            "/img/tile_10.png",
            "/img/tile_11.png",
            "/img/tile_12.png",
            "/img/tile_13.png",
            "/img/tile_14.png",
            "/img/tile_15.png",
          ]}
          edge={56}
          duration={20}
          height={130}
          gap={8}
        />
      </TailContainer>
      <div
        css={css`
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 8px;
          z-index: 1;
          height: 100%;
          padding: 0 13%;
          border-radius: 12px;
          background: linear-gradient(
            to right,
            rgba(245, 245, 245, 1) 90%,
            rgba(245, 245, 245, 0) 100%
          );
          @media (max-width: 768px) {
            padding: 0 24px;
          }
        `}
      >
        <Text
          css={css`
            font-weight: 400;
            white-space: pre-wrap;

            @media (max-width: 768px) {
              ${theme.typography.body1};
            }
          `}
          variant="h3"
          tag="h1"
        >
          {`마케터들의 영감이 쌓이는 공간,\n믹스로 초대합니다.`}
        </Text>
        <Text
          css={css`
            @media (max-width: 768px) {
              display: none;
            }
          `}
          variant="body3"
          color="text.disabled"
          tag="h2"
        >
          {`마케팅 인사이트가 돋보이는\n콘텐츠를 엄선하여 소개합니다.`}
        </Text>
      </div>
    </Container>
  );
};
