import { ComponentProps, useMemo } from "react";
import { useRouter } from "next/router";
import { SectionScaffold } from "./SectionScaffold";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import { ContentGridMixContentQuery } from "components/ContentGridMixContentQuery";
import { ContentGridQuery } from "components/ContentGridQuery";
import { Empty } from "components/Empty";
import { LIMIT } from "utils/buildQueryVariables";
import {
  MIX_CONTENT_TAG_ENUM_KOREAN,
  ORDERED_MIX_CONTENT_TAG_ENUM,
} from "utils/enum";

export const MixedSection = ({
  contentSection,
  variables,
  limit = LIMIT.default,
  ...props
}: Omit<
  ComponentProps<
    typeof ContentGridQuery<MixContentQuery, MixContentQueryVariables>
  >,
  "useQuery" | "extractor" | "updateQuery"
> & { css?: Interpolation<Theme> }) => {
  const theme = useTheme();
  const router = useRouter();

  const tag = useMemo(
    () =>
      ORDERED_MIX_CONTENT_TAG_ENUM.find((tag) => tag === router.query.tag) ??
      null,
    [router],
  );

  return (
    <SectionScaffold {...props}>
      <SectionScaffold.Header>
        <SectionScaffold.Title>
          <span>최신 콘텐츠</span>
          {tag !== null && (
            <span
              css={css`
                margin-left: 12px;
                color: ${theme.palette.text.placeholder};

                @media (max-width: 1024px) {
                  display: none;
                }
              `}
            >
              #{MIX_CONTENT_TAG_ENUM_KOREAN[tag]}
            </span>
          )}
        </SectionScaffold.Title>
      </SectionScaffold.Header>
      <ContentGridMixContentQuery
        type="mixed"
        contentSection={contentSection}
        limit={limit}
        variables={variables}
        empty={
          <Empty
            imgurl="/img/content-empty.png"
            mainText="추천할 만한 콘텐츠를 열심히 찾고 있어요."
            subText="조금만 기다려 주세요!"
          />
        }
      />
    </SectionScaffold>
  );
};
